<template>
  <div class="note-user-details">
    <div class="row gy-4 mb-4">
      <div class="col col-12 col-md-3 flex-column align-content-center">
        <Avatar
          :src="user.avatar"
          size="100%"
          class="w-100"
          with-change
          @upload="updateAvatar"
        ></Avatar>
        <div class="d-flex justify-content-center mt-2">
          <outline-button
            color="danger"
            v-if="user.avatar"
            @click="deleteAvatar"
            >Удалить аватар
          </outline-button>
        </div>
      </div>
      <div class="col col-12 col-md-6">
        <div class="note-last-name">{{ user.last_name }}</div>
        <div class="note-first-middle-name">
          {{ officialName }}
        </div>
        <div class="note-data">
          <div v-for="(data, key) in baseData" :key="key">
            <div>{{ data.title }}</div>
            <ProfileNoteDetailsInput
              class="d-flex justify-content-between align-items-baseline"
              v-bind="data"
              @input="e => changeDate(key, e)"
            >
            </ProfileNoteDetailsInput>
          </div>
          <div v-if="!user.email_confirm">
            <div>
              Подтвердить email
            </div>
            <div>
              <filled-button color="primary" @click="sendConfirm"
                >Отправить</filled-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col col-12 col-md-3 note-actions">
        <div class="d-grid gap-1">
          <filled-button
            block
            class="mb-1"
            color="primary"
            v-if="!changePassword"
            @click="() => (changePassword = true)"
          >
            Сменить пароль
          </filled-button>
          <validation-observer ref="observer" v-show="changePassword">
            <form @submit.prevent.stop="changePass">
              <div class="row">
                <base-input
                  v-model="oldPassword"
                  label="Старый пароль"
                  type="password"
                  class="mb-2"
                  required
                  :validate-disabled="false"
                ></base-input>
                <base-input
                  v-model="password"
                  label="Новый пароль"
                  type="password"
                  class="mb-2"
                  required
                  :validate-disabled="false"
                ></base-input>
                <base-input
                  v-model="password2"
                  label="Повторите пароль"
                  type="password"
                  :equal="{ field: 'Пароль', equal: password }"
                  class="mb-2"
                  required
                  :validate-disabled="false"
                ></base-input>
                <div class="col col-12">
                  <div class="row w-100 g-2 m-0 flex-md-row-reverse">
                    <div class="col col-12 col-md-6">
                      <filled-button
                        block
                        color="success"
                        type="submit"
                        class="w-100"
                      >
                        Отправить
                      </filled-button>
                    </div>
                    <div class="col col-12 col-md-6">
                      <filled-button
                        color="warning"
                        @click="() => (changePassword = false)"
                        class="w-100"
                      >
                        Отменить
                      </filled-button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </validation-observer>
        </div>
      </div>
    </div>
    <title-strip v-if="eventRequests && eventRequests.length"
      >Ваши заявки</title-strip
    >
    <table
      class="table table-striped table-hover table-mobile"
      v-if="!loadingRequests && eventRequests && eventRequests.length"
    >
      <thead>
        <tr>
          <th scope="col">№</th>
          <th>
            Мероприятие
          </th>
          <th>
            Секция
          </th>
          <th>
            Тип заявки
          </th>
          <th scope="col">
            <Tooltip title="Исходящие">
              <SvgIcon icon="inbox-out" color="current" height="16"></SvgIcon>
            </Tooltip>
          </th>
          <th scope="col">
            <Tooltip title="Входящие">
              <SvgIcon icon="inbox-in" color="current" height="16"></SvgIcon>
            </Tooltip>
          </th>
          <th scope="col">Статус</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="eventRequest in eventRequests" :key="eventRequest.id">
          <th scope="row">
            <span>Номер заявки</span>
            <Tooltip title="Открыть в этом окне">
              <router-link
                :to="{ name: 'NoteDetails', params: { id: eventRequest.id } }"
              >
                {{ eventRequest.id }}
              </router-link>
            </Tooltip>
          </th>
          <td>
            <span>Мероприятие</span>
            <span v-if="eventRequest.event"
              ><router-link
                :to="{
                  name: 'EventDetails',
                  params: { slug: eventRequest.event.slug }
                }"
                >{{ eventRequest.event.title }}</router-link
              ></span
            >
            <span v-else>
              Отсутствует
            </span>
          </td>
          <td>
            <span>Секция</span>
            <span v-if="eventRequest.section"
              >{{ eventRequest.section.title }} -
              {{ eventRequest.section.short_description }}</span
            >
            <span v-else>
              Не указана
            </span>
          </td>
          <td>
            <span>Тип заявки</span>
            <span v-if="eventRequest.participation_type">
              {{ eventArticleType(eventRequest.participation_type) }}
            </span>
            <span v-else>
              Не указана
            </span>
          </td>
          <td>
            <span>Исходящие</span>
            <span v-if="eventRequest.unread_for_admin_count">
              <Tooltip title="Не прочитано">
                <SvgIcon icon="envelope" color="warning" height="16"></SvgIcon>
                {{ eventRequest.unread_for_admin_count }}
              </Tooltip>
            </span>
            <span v-else>
              <Tooltip title="Прочитано">
                <SvgIcon
                  icon="envelope-open"
                  color="success"
                  height="16"
                ></SvgIcon>
              </Tooltip>
            </span>
          </td>
          <td>
            <span>Входящие</span>
            <span v-if="eventRequest.unread_for_user_count">
              <Tooltip title="Не прочитано">
                <SvgIcon icon="envelope" color="warning" height="16"></SvgIcon>
                {{ eventRequest.unread_for_user_count }}
              </Tooltip>
            </span>
            <span v-else>
              <Tooltip title="Прочитано">
                <SvgIcon icon="envelope-open" color="success" height="16">
                </SvgIcon>
              </Tooltip>
            </span>
          </td>
          <td>
            <span>Статус</span>
            <span>{{ requestStatus(eventRequest.status) }}</span>
          </td>
          <td>
            <span>Открыть в новом окне</span>
            <Tooltip title="Открыть в новом окне">
              <router-link
                :to="{ name: 'NoteDetails', params: { id: eventRequest.id } }"
                target="_blank"
              >
                <SvgIcon
                  color="current"
                  icon="external-link"
                  height="16"
                  hover="primary"
                ></SvgIcon>
              </router-link>
            </Tooltip>
          </td>
        </tr>
      </tbody>
    </table>
    <title-strip v-if="loadingRequests">загрузка</title-strip>
    <div
      class="d-flex justify-content-center"
      v-if="pageCount && pageCount > 1"
    >
      <div class="btn-group">
        <OutlineButton
          color="primary"
          @click="() => changePage(1)"
          :class="{ active: this.pageNow === 1 }"
        >
          1
        </OutlineButton>
        <OutlineButton color="primary" class="disabled" v-if="pageNow > 3">
          ...
        </OutlineButton>
        <OutlineButton
          v-if="pageNow - 1 > 1"
          color="primary"
          @click="() => changePage(pageNow - 1)"
        >
          {{ pageNow - 1 }}
        </OutlineButton>
        <OutlineButton
          v-if="pageNow > 1 && pageNow < pageCount"
          color="primary"
          @click="() => changePage(pageNow)"
          :class="{ active: this.pageNow === pageNow }"
        >
          {{ pageNow }}
        </OutlineButton>
        <OutlineButton
          v-if="pageNow + 1 < pageCount"
          color="primary"
          @click="() => changePage(pageNow + 1)"
        >
          {{ pageNow + 1 }}
        </OutlineButton>
        <OutlineButton
          color="primary"
          class="disabled"
          v-if="pageNow < pageCount - 2"
        >
          ...
        </OutlineButton>
        <OutlineButton
          color="primary"
          @click="() => changePage(pageCount)"
          :class="{ active: this.pageNow === pageCount }"
        >
          {{ pageCount }}
        </OutlineButton>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "../components/base/Avatar";
import { mapGetters } from "vuex";
import ProfileNoteDetailsInput from "../components/inputs/ProfileNoteDetailsInput";
import API from "../common/api/api.functions";
import OutlineButton from "../components/buttons/OutlineButton";
import FilledButton from "../components/buttons/FilledButton";
import BaseInput from "../components/inputs/BaseInput";
import { ValidationObserver } from "vee-validate";
import TitleStrip from "../components/base/TitleStrip";
import SvgIcon from "../components/base/SvgIcon";
import Tooltip from "../components/base/Tooltip";

export default {
  name: "PrivateArea",
  components: {
    Tooltip,
    TitleStrip,
    BaseInput,
    FilledButton,
    OutlineButton,
    ProfileNoteDetailsInput,
    Avatar,
    SvgIcon,
    ValidationObserver
  },
  data: () => ({
    baseData: [],
    changePassword: false,
    oldPassword: null,
    password: null,
    password2: null,
    pageSize: 5,
    pageNow: 1,
    eventRequests: null,
    filteredRequestsCount: 0,
    loadingRequests: false
  }),
  computed: {
    ...mapGetters(["user"]),
    ...mapGetters(["eventArticleType", "eventArticleTypes", "requestStatus"]),
    pageCount() {
      if (!this.filteredRequestsCount) return 0;
      return Math.ceil(this.filteredRequestsCount / this.pageSize);
    },
    officialName() {
      let names = [];
      if (this.user.first_name) names.push(this.user.first_name);
      if (this.user.middle_name) names.push(this.user.middle_name);
      return names.join(" ");
    }
  },
  methods: {
    async changePage(page) {
      this.pageNow = page;
      await this.loadRequests();
    },
    async loadRequests() {
      this.loadingRequests = true;
      let eventRequests = await API.getUserRequestList(
        this.pageNow,
        this.pageSize,
        {
          section: this.sectionSelected,
          search: this.searchNow
        }
      )
        .then(res => res.data)
        .catch(error => {
          this.$toast.error(JSON.stringify(error?.response?.data || {}));
          // this.notFound = true;
        });
      this.filteredRequestsCount = eventRequests.count || 0;
      this.eventRequests = eventRequests.results || [];
      this.loadingRequests = false;
    },
    async sendConfirm() {
      await API.sendConfirm()
        .then(() =>
          this.$toast.success("Письмо для подтверждения почты отправлено")
        )
        .catch(() => this.$toast.error("Произошла ошибка при отправке почты"));
    },
    updateBaseDate() {
      this.baseData = [
        {
          updateKey: "last_name",
          title: "Фамилия",
          value: this.user.last_name,
          type: "text",
          editable: true,
          required: true
        },
        {
          updateKey: "first_name",
          title: "Имя",
          value: this.user.first_name,
          type: "text",
          editable: true,
          required: true
        },
        {
          updateKey: "middle_name",
          title: "Отчество",
          value: this.user.middle_name,
          type: "text",
          editable: true
        },
        {
          updateKey: "sex",
          title: "Пол",
          value: this.user.sex,
          inline: true,
          button: true,
          buttonGroup: true,
          type: "radio_buttons",
          editable: true,
          required: true,
          items: [
            {
              label: "мужчина",
              value: "male"
            },
            {
              label: "женщина",
              value: "female"
            }
          ]
        },
        {
          updateKey: "birthday",
          title: "Дата рождения",
          value: this.user.birthday,
          type: "date",
          required: true,
          editable: true
        },
        {
          updateKey: "phone",
          title: "Телефон",
          value: this.user.phone,
          type: "tel",
          required: true,
          editable: true
        },
        {
          updateKey: "email",
          title: "E-mail",
          value: this.user.email,
          type: "email",
          required: true,
          editable: true
        }
      ];
    },
    async changeDate(key, value) {
      const updateKey = this.baseData[key]?.updateKey;
      if (!updateKey) {
        this.$toast.error("Непредвиденная ошибка");
      }
      let outValue = value;
      if (this.baseData[key]?.type === "radio_buttons") outValue = outValue[0];
      if (this.baseData[key]?.type === "date") {
        outValue = new Date(outValue).toJSON().split("T")[0];
      }
      if (this.baseData[key]?.type === "datetime") {
        outValue = new Date(outValue).toJSON().replace("Z", "");
      }
      if (this.baseData[key]?.type === "time") {
        outValue = new Date(outValue)
          .toJSON()
          .split("T")[1]
          .replace("Z", "");
      }

      const newUserData = await API.patchUser({
        [updateKey]: outValue
      })
        .then(res => {
          this.$toast.success("Информация обновлена");
          return res.data;
        })
        .catch(error => {
          this.$toast.error(JSON.stringify(error?.response?.data || {}));
          return null;
        });
      if (newUserData) this.$store.commit("UPDATE_USER", newUserData);
    },
    async changePass() {
      const newUserData = await API.patchUser({
        old_pass: this.oldPassword,
        new_pass: this.password
      })
        .then(res => {
          this.$toast.success("Пароль изменён");
          this.oldPassword = null;
          this.password = null;
          this.password2 = null;
          this.changePassword = false;
          return res.data;
        })
        .catch(error => {
          this.$toast.error(JSON.stringify(error?.response?.data || {}));
          return null;
        });
      if (newUserData) this.$store.commit("UPDATE_USER", newUserData);
    },
    async updateAvatar(file) {
      if (file) {
        const formData = new FormData();
        formData.set("change_avatar", file);
        const newUserData = await API.patchUser(formData)
          .then(res => {
            this.$toast.success("Загрузка аватара завершена");
            return res.data;
          })
          .catch(error => {
            this.$toast.error(JSON.stringify(error?.response?.data || {}));
            return null;
          });
        if (newUserData) this.$store.commit("UPDATE_USER", newUserData);
      }
    },
    async deleteAvatar() {
      const newUserData = await API.patchUser({ delete_avatar: true })
        .then(res => {
          this.$toast.success("Аватар успешно удален");
          return res.data;
        })
        .catch(error => {
          this.$toast.error(JSON.stringify(error?.response?.data || {}));
          return null;
        });
      if (newUserData) this.$store.commit("UPDATE_USER", newUserData);
    }
  },
  watch: {
    user() {
      this.updateBaseDate();
    }
  },
  async mounted() {
    this.loading++;
    this.updateBaseDate();
    this.loading--;
    await this.loadRequests();
  }
};
</script>
